// import Debug from '../Lib/Debug'
// import Axios from 'axios'

export default {

  // setCommonTokenToHeader() {
  //   const config = {
  //     headers: {
  //       'Authorization': 'Bearer iGHAndZDSUvadTscv1QtWYjSWf71qPnhiZYMLDBcDzBYygwFOyDPfYoZZquQ'
  //     },
  //   }

  //   return config;
  // },

  setTokenToHeader() {
    const config = {
      headers: {
        'Authorization': 'Bearer ' + sessionStorage.getItem("token"),
        'X-user-id': sessionStorage.getItem("userid")
      }
    }

    return config;
  },

  setTokenToHeaderBlob() {
    const config = {
      headers: {
        'Authorization': 'Bearer ' + sessionStorage.getItem("token"),
        'X-user-id': sessionStorage.getItem("userid")
      },
      'responseType': 'blob',
    }

    return config;
  },

  setTokenToHeaderFile() {
    const config = {
      headers: {
        'Authorization': 'Bearer ' + sessionStorage.getItem("token"),
        'X-user-id': sessionStorage.getItem("userid")
      },
      'Content-Type': 'multipart/form-data',
    }

    return config;
  },

  // setBasicAuthToHeader() {
  //   const config = {
  //     headers: {
  //       'Authorization': 'Basic ' + 'dmlld2VyOnZpZXdlcjAw'
  //     }
  //   }

  //   return config;
  // },

  // /**
  //  * token の期限を確認
  //  * @param {*}
  //  * @returns
  //  */
  // async checkToken() {
  //   Debug.log("function[checkToken]");

  //   const token = sessionStorage.getItem("token");
  //   const tokeninfo = this.decodeJwt(token);
  //   const now = Date.now().valueOf() / 1000;

  //   // tokenの期限を確認
  //   if (tokeninfo.exp >= now) return;

  //   // token の refresh 処理
  //   Debug.log("token limit   : " + new Date(tokeninfo.exp * 1000).toLocaleString());
  //   Debug.log("now date/time : " + new Date(now * 1000).toLocaleString());

  //   try {
  //     const res = await Axios.get('/api/refresh', this.setTokenToHeader());
  //     // 保管している token を更新
  //     sessionStorage.setItem("token", res.data.access_token);

  //     Debug.log(res);
  //     Debug.log("old token : " + token);
  //     Debug.log("new token : " + res.data.access_token);

  //   } catch (error) {
  //     const message = "token の更新に失敗しました";
  //     console.error(message);
  //   }
  // },

  // /**
  //  * token をデコードする
  //  * @param {*} token
  //  * @returns
  //  */
  //  decodeJwt(token)
  //  {
  //    const base64Url = token.split('.')[1];
  //    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  //    return JSON.parse(decodeURIComponent(escape(window.atob(base64))));
  //  },

}
  