<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>国交省登録情報 資料作成</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="auto" class="pt-0 pb-0 mb-n2">{{mlitData.input_date | formatJP()}} 時点</v-col>
      <v-col cols="auto" class="pt-0 pb-0 mb-n2">件数 {{mlitData.office_num}} 件</v-col>
    </v-row>

    <!-- 事業者データ -->
    <v-form
      class="mt-10 mb-5"
      ref="formMlitList"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              事業者データ
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading" @click="getMlitOfficeData()"><v-icon :small="true">mdi-download</v-icon>ダウンロード</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form>

    <!-- 倉庫登録情報 -->
    <v-form
      class="mt-10 mb-5"
      ref="formRegistInfo"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              倉庫登録情報
            </v-col>
            <v-col cols="2" sm="2" md="2" lg="2">
              <v-radio-group 
                v-model="registInfoSumType"
                dense
                row
                :readonly="false"
                :rules="[]"
                :loading="loading"
                class="mt-0 pt-0"
              >
                <v-radio
                  label="数値"
                  :value="0"
                ></v-radio>
                <v-radio
                  label="有無"
                  :value="1"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading" @click="getWarehouseInfo()"><v-icon :small="true">mdi-download</v-icon>ダウンロード</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form>

    <!-- 築年数資料 -->
    <v-form
      class="mt-10 mb-5"
      ref="formMlitDB"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              築年数資料
            </v-col>
            <v-col cols="2" sm="2" md="2" lg="2">
              <v-radio-group 
                v-model="mlitDBSumType"
                dense
                row
                :readonly="false"
                :rules="[]"
                :loading="loading"
                class="mt-0 pt-0"
              >
                <v-radio
                  label="数値"
                  :value="0"
                ></v-radio>
                <v-radio
                  label="有無"
                  :value="1"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading" @click="getWarehouseDB()"><v-icon :small="true">mdi-download</v-icon>ダウンロード</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form>

    <!-- 築年数ランキング(国交省版) -->
    <v-form
      class="mt-10 mb-5"
      ref="formMlitList"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              築年数ランキング(国交省版)
            </v-col>
            <v-col cols="2" sm="2" md="2" lg="2">
              <v-radio-group 
                v-model="soukoType"
                dense
                row
                :readonly="false"
                :rules="[]"
                :loading="loading"
                class="mt-0 pt-0"
              >
                <v-radio
                  label="冷蔵倉庫"
                  :value="0"
                ></v-radio>
                <v-radio
                  label="普通倉庫"
                  :value="1"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading" @click="getInstallation()"><v-icon :small="true">mdi-download</v-icon>ダウンロード</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form>

    <!-- 築年数ランキング -->
    <v-form
      class="mt-10 mb-5"
      ref="formRank"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              築年数ランキング
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading" @click="getInstallationRank()"><v-icon :small="true">mdi-download</v-icon>ダウンロード</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form>

    <!-- 国交省倉庫一覧 -->
    <!-- <v-form
      class="mt-10 mb-5"
      ref="formMlitList"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              国交省倉庫一覧
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading" @click="getWarehouseList()"><v-icon :small="true">mdi-download</v-icon>ダウンロード</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form> -->

    <!-- 前年度より変更点 -->
    <!-- <v-form
      class="mt-10 mb-5"
      ref="formMemberList"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              前年度より変更点
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading" @click="getDifference()"><v-icon :small="true">mdi-download</v-icon>ダウンロード</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form> -->

    <!-- 日冷倉棟別明細 -->
    <!-- <v-form
      class="mt-10 mb-5"
      ref="formMemberList"
      v-model="valid"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              日冷倉棟別明細
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
              <v-btn width="200"  color="primary" :disabled="loading" @click="goto_createMlitMemberDetail()"><v-icon :small="true">mdi-file-document-plus-outline</v-icon>作成</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-form> -->

    <!------------------------------------------------------------------------>
    <!-- ぐるぐる -->
    <modal name="modal-dialog" :draggable="false" :clickToClose="false" :scrollable="true" height="auto" id="vm--modal">
      <div>
        <h3 class="center mt-5 action-message">{{actionMessage}}</h3> 
        <Spinner size="150" class="spinner" ></Spinner>
      </div>
    </modal>

  </v-container>
</template>

<script>
import dayjs from "dayjs";
import Debug from '../../Lib/Debug'
import Message from "../../Lib/Message";
import Mixin from "../../mixins/const.js";
import Spinner from 'vue-simple-spinner'
import saveAs from "file-saver";

import Download from "../../model/download";
import MlitData from "../../model/mlitData";
import MlitOffice from "../../model/mlitOffice";

export default {
  components: {
    Spinner,
  },

  mixins: [Mixin],

  data: () => ({
    valid : true,
    loading: false,

    // 国交省登録情報ID
    mlitDataSid: null,

    // 国交省登録情報データ
    mlitData: [],

    // 事業所データExcel
    officeListFile: '',

    // 倉庫登録情報 集計種別
    registInfoSumType: 0,
    // 築年数資料 集計種別
    mlitDBSumType: 0,
    // 築年数ランキング(国交省版) 倉庫種別
    soukoType: 0,

    actionMessage: '',
  }),

  // ライフサイクルフック
  created: function () {
    Debug.log("created");

  },

  mounted: async function () {
    // POSTパラメータを取得
    this.mlitDataSid = this.$route.params.editId;
    //this.officeListFile = this.$route.params.officeListFile;

    this.loadData();
  },

  methods: {
    loadData() {
      Debug.log("function[loadData]");

      this.loading = true;
      // DBからデータ取得
      MlitData.getData(this.mlitDataSid)
        .then(res => {
          if (res && res.data) {
            this.mlitData = res.data;
            Debug.log(this.mlitData);
          }
        })
        .catch((err) => {
          Message.err(err, "国交省登録情報を取得できませんでした");
        })
        .finally(() => {
          this.loading = false;
        });
      // this.inputData = this.mokInputData;
    },

    /** 事業者データダウンロード */
    // async getMlitOfficeData() {
    //   try {
    //     if (this.officeListFile != null && this.officeListFile != '') {
    //       // 作成に成功したらExcelダウンロード
    //       const downloadFile = this.officeListFile;
    //       const formatDate = new Date(this.mlitData.input_date);
    //       const inputDateStr = formatDate.toLocaleDateString("ja-JP-u-ca-japanese", {year:'2-digit',month:'2-digit',day:'2-digit'})
    //                                       .replaceAll("/", "")
    //                                       .substr(0, 5);
    //       Debug.log2('inputDateStr:',inputDateStr);
    //       Download.download(downloadFile)
    //         .then(res => {
              
    //           saveAs(res.data, inputDateStr+'事業所データ.xlsx');
    //         })
    //         .catch((err) => {
    //           Message.err(err, "ダウンロードできませんでした");
    //       })
    //     }
    //   } catch (error) {
    //     Message.err(error, "ダウンロードできませんでした");
    //   }
    // },

    /** 事業者データダウンロード */
    async getMlitOfficeData() {
      try {
        this.actionMessage = "事業者データを作成しています。\nしばらくお待ちください。"
        this.$modal.show('modal-dialog');

        Debug.log2('mlitDataSid:', this.mlitDataSid);
        const res = await MlitOffice.getMlitOfficeData(this.mlitDataSid);

        if (res.status == 200 && res.data) {
          // 作成に成功したらExcelダウンロード
          Debug.log(res.data);
          const downloadFile = res.data;
          const formatDate = new Date(this.mlitData.input_date);
          const inputDateStr = formatDate.toLocaleDateString("ja-JP-u-ca-japanese", {year:'2-digit',month:'2-digit',day:'2-digit'})
                                          .replaceAll("/", "")
                                          .substr(0, 5);
          Debug.log2('inputDateStr:',inputDateStr);
          Download.download(downloadFile)
            .then(res => {
              saveAs(res.data, inputDateStr+'事業所データ.xlsx');
            })
            .catch((err) => {
              Message.err(err, "ダウンロードできませんでした");
          })
        }
      } catch (error) {
        Message.err(error, "ダウンロードできませんでした");
      } finally {
        this.$modal.hide('modal-dialog');
      }
    },

    /** 倉庫登録情報 */
    async getWarehouseInfo() {
      try {
        const formatDate = new Date(this.mlitData.input_date);
        const inputDateRMD = formatDate.toLocaleDateString("ja-JP-u-ca-japanese", {year:'numeric',month:'long',day:'numeric'});

        this.actionMessage = "倉庫登録情報を作成しています。\nしばらくお待ちください。"
        this.$modal.show('modal-dialog');

        const res = await MlitOffice.getMlitWarehouseInfo(inputDateRMD, this.registInfoSumType);

        if (res.status == 200 && res.data) {
          // 作成に成功したらExcelダウンロード
          Debug.log(res.data);
          const downloadFile = res.data;
          // ファイル名
          const inputDate = dayjs(this.mlitData.input_date).format('YYYYMMDD');
          let fileName = "";
          if(this.registInfoSumType == 0) {
            fileName = inputDate+' 倉庫登録情報(参考数値).xlsx';
          } else {
            fileName = inputDate+' 倉庫登録情報.xlsx';
          }

          Download.download(downloadFile)
            .then(res => {
              saveAs(res.data, fileName);
            })
            .catch((err) => {
              Message.err(err, "ダウンロードできませんでした");
          })
        }
      } catch (error) {
        Message.err(error, "ダウンロードできませんでした");
      } finally {
        this.$modal.hide('modal-dialog');
      }
    },

    /** 築年数資料 */
    async getWarehouseDB() {
      try {
        const formatDate = new Date(this.mlitData.input_date);
        const inputDateRMD = formatDate.toLocaleDateString("ja-JP-u-ca-japanese", {year:'numeric',month:'long',day:'numeric'});
        const fileNameRMD = formatDate.toLocaleDateString("ja-JP-u-ca-japanese", {year:'2-digit',month:'2-digit'}).replaceAll("/", "")

        Debug.log2('fileNameRMD:',fileNameRMD);

        this.actionMessage = "築年数資料を作成しています。\nしばらくお待ちください。"
        this.$modal.show('modal-dialog');

        const res = await MlitOffice.getMlitWarehouseDB(inputDateRMD, fileNameRMD, this.mlitDBSumType);

        if (res.status == 200 && res.data) {
          // 作成に成功したらExcelダウンロード
          Debug.log(res.data);
          const downloadFile = res.data;
          // ファイル名
          const inputDate = dayjs(this.mlitData.input_date).format('YYYYMMDD');
          const fileName = inputDate+'_倉庫業者DBver2.zip';

          Download.download(downloadFile)
            .then(res => {
              saveAs(res.data, fileName);
            })
            .catch((err) => {
              Message.err(err, "ダウンロードできませんでした");
          })
        }
      } catch (error) {
        Message.err(error, "ダウンロードできませんでした");
      } finally {
        this.$modal.hide('modal-dialog');
      }
    },

    /** 築年数ランキング(国交省版) */
    async getInstallation() {
      try {
        this.actionMessage = "築年数ランキング(国交省版)を作成しています。\nしばらくお待ちください。"
        this.$modal.show('modal-dialog');

        const res = await MlitOffice.getMlitInstallation(this.mlitData.input_date, this.soukoType);

        if (res.status == 200 && res.data) {
          // 作成に成功したらExcelダウンロード
          Debug.log(res.data);
          const downloadFile = res.data.Download_path;
          // ファイル名
          const inputDate = dayjs(this.mlitData.input_date).format('YYYYMM');
          let fileName = "";
          if(this.soukoType == 0) {
            fileName = inputDate+'_冷蔵倉庫 築年数(Excel版).xlsx';
          } else {
            fileName = inputDate+'_普通倉庫 築年数(Excel版).xlsx';
          }

          Download.download(downloadFile)
            .then(res => {
              saveAs(res.data, fileName);
            })
            .catch((err) => {
              Message.err(err, "ダウンロードできませんでした");
          })
        }
      } catch (error) {
        Message.err(error, "ダウンロードできませんでした");
      } finally {
        this.$modal.hide('modal-dialog');
      }
    },

    /** 築年数ランキング */
    async getInstallationRank() {
      try {
        this.actionMessage = "築年数ランキングを作成しています。\nしばらくお待ちください。"
        this.$modal.show('modal-dialog');

        const res = await MlitOffice.getMlitInstallationRank(this.mlitData.input_date);

        if (res.status == 200 && res.data) {
          // 作成に成功したらExcelダウンロード
          Debug.log(res.data);
          const downloadFile = res.data.Download_path;
          // ファイル名
          //const inputDate = dayjs(this.mlitData.input_date).format('YYYYMM');
          let fileName = "⑥築年数ﾗﾝｷﾝｸﾞ.xlsx";

          Download.download(downloadFile)
            .then(res => {
              saveAs(res.data, fileName);
            })
            .catch((err) => {
              Message.err(err, "ダウンロードできませんでした");
          })
        }
      } catch (error) {
        Message.err(error, "ダウンロードできませんでした");
      } finally {
        this.$modal.hide('modal-dialog');
      }
    },

    /** 国交省倉庫一覧 */
    async getWarehouseList() {
      try {
        this.actionMessage = "国交省倉庫一覧を作成しています。\nしばらくお待ちください。"
        this.$modal.show('modal-dialog');

        const res = await MlitOffice.getMlitWarehouseList();

        if (res.status == 200 && res.data) {
          // 作成に成功したらExcelダウンロード
          Debug.log(res.data);
          const downloadFile = res.data.Download_path;
          // ファイル名
          const fileName = "国交省倉庫一覧.xlsx";

          Download.download(downloadFile)
            .then(res => {
              saveAs(res.data, fileName);
            })
            .catch((err) => {
              Message.err(err, "ダウンロードできませんでした");
          })
        }
      } catch (error) {
        Message.err(error, "ダウンロードできませんでした");
      } finally {
        this.$modal.hide('modal-dialog');
      }
    },

    /** 前年度より変更点 */
    async getDifference() {
      try {
        this.actionMessage = "前年度より変更点を作成しています。\nしばらくお待ちください。"
        this.$modal.show('modal-dialog');

        const res = await MlitOffice.getMlitDifference();

        if (res.status == 200 && res.data) {
          // 作成に成功したらExcelダウンロード
          Debug.log(res.data);
          const downloadFile = res.data.Download_path;
          // ファイル名
          const fileName = "⑤前年度より変更点.xlsx";

          Download.download(downloadFile)
            .then(res => {
              saveAs(res.data, fileName);
            })
            .catch((err) => {
              Message.err(err, "ダウンロードできませんでした");
          })
        }
      } catch (error) {
        Message.err(error, "ダウンロードできませんでした");
      } finally {
        this.$modal.hide('modal-dialog');
      }
    },
  },

  watch: {

  },
};
</script>

<style scoped>
/* ぐるぐる */
.action-message {
  white-space: pre-line;
}
.spinner {
  margin: 50px;
}
</style>
