import Axios from 'axios'
import Debug from '../Lib/Debug';
import token from './setToken'

export default {

  async download(file) {
    try {
  
      // const res = await Axios.get('/api/download/' + file, token.setTokenToHeaderBlob());

      Debug.log(file)
      let params = new URLSearchParams();
      params.append('file', file);
      const res = await Axios.post('/api/download', params, token.setTokenToHeaderBlob());
      return res;

    } catch (error) {
      console.error("画像データを取得できません");
      throw error;
    }
  },

}
